<template>
  <section id="create-report">
    <b-card>
      <div>
        <h1>
          <a @click="backListReport"> <feather-icon icon="ChevronLeftIcon" size="24" class="mr-1 align-text-top" /> </a>
          {{ this.report_id ? this.form.name : 'Nuevo reporte programado' }}
        </h1>
      </div>
      <template>
        <div>
          <div class="mt-2">
            <form-render class="mb-2" :fields="fields" :key="formRenderReports" :form.sync="form"
              @send="updateOrSaveReportDetails" ref="formRenderReports"
              :disableAll="loading.first || loading.fields || loading.stages || loading.users || loading.save"
              containerButtonsClass="col-sm-12 col-md-3 container-button">

              <template #stages>
                <div class="container-info">
                  <label class="label-form" :class="error_stages ? 'text-danger mb-1' : 'mb-1'"
                    title="Etapas que desea incluir en el reporte (seleccione)">
                    <span>Etapas que desea incluir en el reporte (seleccione)</span>
                    <span class="text-danger"> *</span>
                  </label>
                  <div>
                    <span v-for="(stage, index) in stages" :key="`saved_${index}`" class="m-0-5">
                      <b-badge
                        :variant="stage.saved ? 'primary' : !form.types || loading.stages ? 'outlined-default' : 'outlined-primary'"
                        @click="selectStage(stage, form.types)" class="mb-1">{{
                          stage.name }}</b-badge>
                    </span>
                  </div>
                </div>
              </template>
              <template #fields>
                <div class="container-info">
                  <label class="label-form" :class="error_fields ? 'text-danger mb-1' : 'mb-1'"
                    title="Campos que desea incluir en el reporte (desmarque los que no desea incluir)">
                    <span>Campos que desea incluir en el reporte (desmarque los que no desea incluir)</span>
                    <span class="text-danger"> *</span>
                  </label>
                  <div>
                    <span v-for="(field, index) in fields_report" :key="`saved_${index}`" class="m-0-5">
                      <b-badge
                        :variant="field.saved ? 'primary' : !form.types || loading.fields ? 'outlined-default' : 'outlined-primary'"
                        @click="selectField(field, form.types)" class="mb-2"><i v-if="field.saved"
                          class="fa fa-check-circle"></i> {{
                            field.name }}</b-badge>
                    </span>
                  </div>
                </div>
              </template>
              <template #recurrence_form>
                <div>
                  <label class="label-form" title="Frecuencia de envío del reporte">
                    <span>Desea recibir el reporte cada</span>
                    <span class="text-danger"> *</span>
                  </label>
                  <div>
                    <b-form-input :class="amount_recurrence_classes.join(' ')"
                      v-model="form.recurrence_form.amount_recurrence" type="number" min="1" max="10"
                      placeholder="seleccione" />
                    <b-form-group>
                      <v-select :class="recurrence_classes.join(' ')" v-model="form.recurrence_form.recurrence"
                        :dir="'ltr'" :clearable="false" label="text" :options="recurrenceOptions"
                        :reduce="option => option"
                        @input="changeRecurrence('recurrence', form.recurrence_form.recurrence)"
                        placeholder="seleccione" />
                    </b-form-group>
                  </div>
                  <div
                    :class="amount_recurrence_classes.includes('is-invalid') || recurrence_classes.includes('is-invalid') ? 'invalid-feedback d-block' : 'invalid-feedback'">
                    Este campo es
                    requerido </div>
                </div>
              </template>
              <template #recipients>
                <b-form-group>
                  <div>
                    <label :class="error_recipients ? 'text-danger label-form' : 'label-form'"
                      title="Frecuencia de envío del reporte" for="tags-recipients">
                      <span>Destinatarios</span>
                      <span class="text-danger"> *</span>
                    </label>
                    <b-form-tags input-id="tags-recipients" v-model="form.recipients" separator=" ," remove-on-delete
                      placeholder="Ingresa los email de los destinatarios" tag-variant="primary"
                      :tag-validator="tagUserValidator" @tag-state="onTagUserState" required
                      invalidTagText="email incorrecto" duplicateTagText="email duplicado" tag-pills size="m"
                      @input="changeRecipients">
                    </b-form-tags>
                    <div :class="error_recipients ? 'invalid-feedback d-block' : 'invalid-feedback'">
                      Este campo es
                      requerido </div>
                  </div>
                </b-form-group>
              </template>
            </form-render>
            <b-button variant="warning" class="push-right col-sm-12 col-md-2" @click="send">
              <label v-if="!loading.save" class="color-white">Guardar</label>
              <label v-else>Cargando...</label>
            </b-button>
          </div>
        </div>
      </template>
    </b-card>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import ReportsService from './reports.service'
import vSelect from 'vue-select'
const moment = require('moment')

export default {
  name: 'automatic-reports-create',
  components: {
    vSelect
  },
  data() {
    return {
      form: {},
      types: null,
      fields: [],
      validate: false,
      items: [],
      loading: {
        report: false,
        first: true,
        stages: false,
        fields: false,
        save: false,
        users: false
      },
      shipper_id: null,
      organization_id: null,
      time_zone: null,
      formRenderReports: 0,
      report_id: this.$route.params.id,
      reportsService: new ReportsService(this),
      error_stages: false,
      error_fields: false,
      error_recipients: false,
      daysSelected: [],
      stages: [],
      carriers: [],
      shippers: [],
      organizations: [],
      initalForm: null,
      validUsers: [],
      invalidUsers: [],
      duplicateUsers: [],
      buttonSend: { color: 'warning', title: 'Guardar', text: 'Guardar', class: 'push-right col-sm-12 col-md-3 col-lg-3 mt-5 ml-0' },
      fields_report: [],
      data_fields: null,
      recurrence_classes: ['col-md-12', 'pl-0', 'out-border-left'],
      amount_recurrence_classes: ['col-md-8', 'push-left', 'out-border-right'],
      ranges_data: [
        { 'id': 'last_day', 'name': 'Último día', 'text': 'Último día' },
        { 'id': 'last_seven_days', 'name': 'Últimos 7 días', 'text': 'Últimos 7 días' },
        { 'id': 'last_thirty_days', 'name': 'Últimos 30 días', 'text': 'Últimos 30 días' },
        { 'id': 'last_sixty_days', 'name': 'Últimos 60 días', 'text': 'Últimos 60 días' },
        { 'id': 'last_ninety_days', 'name': 'Últimos 90 días', 'text': 'Últimos 90 días' },
        { 'id': 'last_month', 'name': 'Mes anterior', 'text': 'Mes anterior' }
      ],
      OptionsDays: [
        { 'id': -1, 'name': 'Todos los días', 'text': 'Todos los días' },
        { 'id': 1, 'name': 'Lunes', 'text': 'Lunes' },
        { 'id': 2, 'name': 'Martes', 'text': 'Martes' },
        { 'id': 3, 'name': 'Miercoles', 'text': 'Miercoles' },
        { 'id': 4, 'name': 'Jueves', 'text': 'Jueves' },
        { 'id': 5, 'name': 'Viernes', 'text': 'Viernes' },
        { 'id': 6, 'name': 'Sábado', 'text': 'Sábado' },
        { 'id': 0, 'name': 'Domingo', 'text': 'Domingo' }
      ],
      OptionsDaysOfMonth: [
        { 'id': 1, 'name': 'Primer día del mes', 'text': 'Primer día del mes' },
        { 'id': 5, 'name': 'Día 5 del mes', 'text': 'Día 5 del mes' },
        { 'id': 15, 'name': 'Día 15 del mes', 'text': 'Día 15 del mes' },
        { 'id': 31, 'name': 'Último día del mes', 'text': 'Último día del mes' }
      ],
      recurrenceOptions: [
        // { 'id': 'hourly', 'name': 'Hora', 'text': 'Hora' },
        { 'id': 'weekly', 'name': 'Semana', 'text': 'Semana' },
        { 'id': 'monthly', 'name': 'Mes', 'text': 'Mes' }
      ],
      recipientsOptions: [
        { 'id': 'juan.suazo@enviame.io', 'name': 'Juan Pablo Suazo', 'text': 'Juan Pablo Suazo' },
        { 'id': 'jean.cuadros@enviame.io', 'name': 'Jean Cuadros', 'text': 'Jean Cuadros' }
      ],
      optionCheckboxs: [
        { 'id': true, 'text': 'Programar fecha de inicio' }
      ]
    }
  },
  mounted() {
    if (this.mySession.id) this.setInitialData()
  },
  watch: {
    'loading.report'(value, oldValue) {
      if (value === false) {
        this.initalForm = JSON.stringify(this.getCurrentForm())
      }
    },
    'stages'() {
      let stages = this.stages.filter(stage => stage.saved === true)
      stages = stages.map(stage => stage.id)
      const form = JSON.parse(this.initalForm)
      if (form) {
        form.stages = stages
        this.initalForm = JSON.stringify(form)
      }
    },
    'fields_report'() {
      let fields = this.fields_report.filter(field => field.saved === true)
      fields = fields.map(field => field.id)
      const form = JSON.parse(this.initalForm)
      if (form) {
        form.fields = fields
        this.initalForm = JSON.stringify(form)
      }
      // this.initalForm = JSON.stringify(this.getCurrentForm())
    },
    'types'() {
      const types = this.form.types
      const form = JSON.parse(this.initalForm)
      if (form) {
        form.types = types
        this.initalForm = JSON.stringify(form)
      }
      // this.initalForm = JSON.stringify(this.getCurrentForm())
    },
    mySession() {
      if (this.mySession.id) this.setInitialData()
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  beforeRouteLeave(to, from, next) {
    if (this.confirmStayInDirtyForm()) {
      this.$yesno('¿Está seguro que desea descartar los cambios?', () => {
        next()
      }, 'Salir sin guardar')
    } else {
      // Navigate to next view
      next()
    }
  },
  methods: {
    setInitialData() {
      if (!['admin', 'super_admin'].includes(this.$session.get('cas_user')?.role)) {
        this.shipper_id = this.$session.get('cas_user').shipper?.id || null
        this.organization_id = this.$session.get('cas_user').organization?.id || null
      }
      this.time_zone = this.$session.get('cas_user').timezone
      this.fields = [
        { fieldType: 'FieldInput', name: 'name', label: 'Nombre del Reporte', containerClass: 'col-sm-12 container-info col-md-7', validation: 'required' },
        { fieldType: 'FieldSelect', name: 'types', label: 'Tipo', containerClass: 'col-sm-12 container-info col-md-7', multiple: false, validation: 'required', change: this.getFielsAndStages },
        { fieldType: 'FieldSelect', name: 'range_data', label: 'Envíos creados el último', containerClass: 'col-sm-12 container-info col-md-3', multiple: false, validation: 'required', options: this.ranges_data },
        { name: 'stages', useSlot: true, containerClass: 'col-sm-12 mt-1 container-info col-md-12' },
        { fieldType: 'FieldSelect', name: 'carriers', label: 'Filtro courier', placeholder: 'Seleccione un courier', containerClass: 'col-sm-12 container-info col-md-7', multiple: false, options: this.carriers },
        { name: 'fields', useSlot: true, containerClass: 'col-sm-12 mt-1 container-info col-md-12' },
        { name: 'recurrence_form', useSlot: true, containerClass: 'col-sm-12 mb-1 col-md-4' },
        { fieldType: 'FieldSelect', name: 'days', label: 'Días que desea recibir el reporte', containerClass: 'col-sm-12 container-info col-md-3', multiple: true, validation: 'required', options: this.OptionsDays, change: this.changeDays, notCloseOnSelect: true },
        { fieldType: 'FieldInput', name: 'time_start', label: 'Horario de inicio', containerClass: 'col-sm-12 container-info col-md-2', validation: 'required', type: 'time' },
        { fieldType: 'FieldInput', name: 'time_end', label: 'y de termino', containerClass: 'col-sm-12 container-info col-md-2', validation: 'required', type: 'time' },
        { fieldType: 'FieldCheckbox', name: 'checkbox_date', label: '', containerClass: 'col-sm-12 col-md-12 col-lg-12 container-info marker', align: 'h', options: this.optionCheckboxs, change: this.changeCheckbox },
        { fieldType: 'FieldDatepicker', label: 'Fecha programación', name: 'start_date', containerClass: 'col-sm-12 col-md-12 col-lg-12 container-info d-none', validation: '', propsVCalendar: { 'min-date': new Date() } },
        { name: 'recipients', useSlot: true, containerClass: 'col-sm-12 mt-1 container-info col-md-12' }
      ]
      if (['superadmin', 'admin'].includes(this.$session.get('cas_user').role)) {
        // add prepend shipper select to array fields
        this.fields.unshift({ fieldType: 'FieldSelect', name: 'shipper_id', label: 'Empresa', placeholder: 'Todas las empresas', containerClass: 'col-sm-12 container-info col-md-7', multiple: false, change: this.changeShipper, options: this.shippers, searchOnType: { fx: this.searchShipper, nChars: 3, debounce: 600 } })
        // add prepend organization select to array fields
        this.fields.unshift({ fieldType: 'FieldSelect', name: 'organization_id', label: 'Marketplace', containerClass: 'col-sm-12 container-info col-md-7', multiple: false, change: this.changeOrganization, options: this.organizations, searchOnType: { fx: this.searchOrganization, nChars: 3, debounce: 600 } })

      }
      else if (this.$session.get('cas_user').role === 'marketplace') {
        // add prepend organization select to array fields
        this.fields.unshift({ fieldType: 'FieldSelect', name: 'shipper_id', label: 'Empresa', placeholder: 'Todas las empresas', containerClass: 'col-sm-12 container-info col-md-7', multiple: false, change: this.changeShipper, options: this.shippers, searchOnType: { fx: this.searchShipper, nChars: 3, debounce: 600 } })
        // this.getShippers(this.organization_id)
      }
      this.getCarriers()
      this.form.start_date = new Date()
      this.form.stages = []
      this.form.types = null
      this.form.recurrence_form = { recurrence: this.recurrenceOptions.find(item => item.id === 'weekly'), amount_recurrence: 1 }
      this.changeRecurrence(true, this.form.recurrence_form.recurrence)
      this.form.fields = []
      this.form.checkbox_date = false
      if (this.report_id) {
        this.getReportDetails()
      } else {
        this.loading.first = false
        this.getTypes()
        this.getFielsAndStages()
        this.initalForm = JSON.stringify(this.getCurrentForm())
      }
    },
    getCurrentForm() {
      const form = {
        'name': this.form.name ?? '',
        'types': this.form?.types ?? null,
        'range_data': this.form.range_data ?? null,
        'stages': this.form.stages,
        'fields': this.form.fields,
        'recurrence': this.form.recurrence_form.recurrence,
        'amount_recurrence': parseInt(this.form.recurrence_form.amount_recurrence),
        'days': this.form.days ?? [],
        'time_start': this.form.time_start ?? '',
        'time_end': this.form.time_end ?? '',
        'start_date': this.form.start_date ?? ''
      }
      if (this.form?.recipients && this.form?.recipients.length > 0) {
        form.recipients = this.form?.recipients
      }
      return form
    },
    confirmStayInDirtyForm() {
      const currentForm = JSON.stringify(this.getCurrentForm())
      return this.initalForm && this.initalForm !== currentForm
    },
    confirmLeave() {
      return this.$yesno('¿Está seguro que desea descartar los cambios?')
    },
    tagUserValidator(tag) {
      const re = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/
      return re.test(tag)
    },
    onTagUserState(valid, invalid, duplicate) {
      this.validUsers = valid
      this.invalidUsers = invalid
      this.duplicateUsers = duplicate
    },
    updateReportDetails(data) {
      const queryParams = data
      this.reportsService.callService('updateReport', queryParams, { id: this.report_id })
        .then(resp => {
          this.loading.save = false
          this.initalForm = JSON.stringify(this.getCurrentForm())
          this.$router.push({ name: 'automatic-reports' })
          this.$success('Reporte actualizado correctamente')
        })
        .catch(err => {
          this.loading.save = false
          console.error(err)
          this.$alert('No se pudo actualizar el reporte')
        })
    },
    saveReportDetails(data) {
      const queryParams = { ...data }
      this.reportsService.callService('createReport', queryParams, {}, { fullResponseError: true })
        .then(resp => {
          this.loading.save = false
          this.initalForm = JSON.stringify(this.getCurrentForm())
          this.$router.push({ name: 'automatic-reports' })
          this.$success('Reporte se ha creado correctamente')
        })
        .catch((err) => {
          this.loading.save = false
          if (err.data?.id) {
            this.$info(err.message, () => { this.openNewWindowUpdate(err.data.id) }, 'Coincidencia en reporte', 'Editar reporte')
          } else {
            this.$alert('No se pudo crear el reporte')
          }
        })
    },
    openNewWindowUpdate(id) {
      this.m_openWinBlank(`/automatic-reports/update/${id}`)
    },
    updateOrSaveReportDetails(data) {
      if (this.error_fields || this.error_stages || this.error_recipients || !this.form.recurrence_form.recurrence || !this.form.recurrence_form.amount_recurrence) {
        return
      }
      this.loading.save = true
      const { types, checkbox_date, days, start_date, range_data, recipients, recurrence_form, shipper_id, organization_id, carriers, ...rest } = data
      const new_start_date = checkbox_date ? this.getDate(start_date) : this.getDate()
      if (shipper_id) {
        this.shipper_id = shipper_id.id
        this.shipper_name = shipper_id.name1
        this.organization_id = shipper_id.organization?.id || this.organization_id
        this.organization_name = shipper_id.organization?.name || this.organization_name
      } else {
        this.shipper_id = null
      }
      if (organization_id) {
        this.organization_id = organization_id.id
        this.organization_name = organization_id.name
      } else {
        this.organization_id = null
      }
      if (!['admin', 'super_admin'].includes(this.$session.get('cas_user')?.role)) {
        this.shipper_id = this.$session.get('cas_user').shipper?.id || null
        this.shipper_name = this.$session.get('cas_user').shipper?.name1 || null
        this.organization_id = this.$session.get('cas_user').organization?.id || null
        this.organization_name = this.$session.get('cas_user').organization?.name || null
      }
      // boolean if shipper_id is null and organization_id is not null, then
      const marketplace = !this.shipper_id && !!this.organization_id
      const newData = {
        ...rest,
        shipper_id: this.shipper_id ?? null,
        shipper_name: this.shipper_name ?? null,
        organization_id: this.organization_id ?? null,
        organization_name: this.organization_name ?? null,
        carriers: carriers?.id ? [carriers.id] : null,
        marketplace,
        type_email_report_id: types?.id,
        range_data: range_data?.id,
        start_date: new_start_date,
        days: days.map(day => day.id),
        recurrence: recurrence_form.recurrence?.id,
        amount_recurrence: recurrence_form.amount_recurrence,
        recipients,
        time_zone: this.time_zone
      }
      if (this.report_id) {
        this.updateReportDetails(newData)
      } else {
        this.saveReportDetails(newData)
      }
    },
    getDate(start_date = new Date()) {
      return moment(start_date).format('YYYY-MM-DD')
    },
    send(e) {
      this.error_stages = this.form.stages.length === 0
      this.error_fields = this.form.fields.length === 0
      this.error_recipients = !this.form.recipients?.length

      if (!this.form.recurrence_form.recurrence) {
        this.recurrence_classes.push('is-invalid')
      } else {
        this.recurrence_classes = this.recurrence_classes.filter(item => item !== 'is-invalid')
      }
      if (!this.form.recurrence_form.amount_recurrence) {
        this.amount_recurrence_classes.push('is-invalid')
      } else {
        this.amount_recurrence_classes = this.amount_recurrence_classes.filter(item => item !== 'is-invalid')
      }
      e.preventDefault()
      this.$refs.formRenderReports.checkForm()
    },
    backListReport() {
      this.$router.push({ name: 'automatic-reports' })
    },
    getOrganizations(id = null, name = null, get_fields = true) {
      let queryParams = { paginate_by: 100, page: 1 }
      if (name) {
        queryParams = { ...queryParams, name }
      }
      this.reportsService.callService('getOrganizations', queryParams, {})
        .then(response => {
          this.organizations = response.data.map(el => ({ ...el, text: el.name }))
          this.fields.find(el => el.name === 'organization_id').options = this.organizations
          if (id) {
            this.form.organization_id = this.organizations.filter(el => el.id === id)[0]
            this.fields.find(el => el.name === 'shipper_id').validation = ''
          }

          // this.fields[0].options = this.organizations

          if (get_fields) {
            this.getFields(true, this.data_fields, this.getCountryId())
          }
        }).catch(err => {
          console.error(err)
          this.$alert('No existen organizaciones')
        })
    },
    searchOrganization(name) {
      const queryParams = { paginate_by: 100, page: 1, name }
      return this.reportsService.callService('getOrganizations', queryParams, {})
        .then(response => {
          return this.organizations = response.data.map(el => ({ ...el, text: el.name }))
        }).catch(err => {
          console.error(err)
          this.$alert('No existen organizaciones')
          return []
        })
    },
    getShippers(organization_id = null, shipper_id = null, name = null, get_fields = true) {
      let queryParams = { paginate_by: 100, page: 1 }
      let pathParams = {}
      if (name) {
        queryParams = { ...queryParams, name }
      }
      let service = 'getShippersByOrganization'
      if (organization_id) {
        pathParams = { organization_id }
        this.form.shipper_id = null
      }
      if (['admin', 'super_admin'].includes(this.$session.get('cas_user').role)) {
        service = 'getShippers'
        if (organization_id) {
          queryParams = { organization_id }
          pathParams = {}
        }
      }

      this.reportsService.callService(service, queryParams, pathParams)
        .then(response => {
          this.shippers = response.data.map(el => ({ ...el, text: el.name1 }))
          this.fields.find(el => el.name === 'shipper_id').options = this.shippers
          if (shipper_id) {
            this.form.shipper_id = this.shippers.filter(el => el.id === shipper_id)[0]
            this.fields.find(el => el.name === 'organization_id').validation = ''
          }

          if (get_fields) {
            this.getFields(true, this.data_fields, this.getCountryId())
          }
        }).catch(err => {
          console.error(err)
          this.$alert('No existen empresas asociadas a la organización')
        })
    },
    searchShipper(name) {
      let queryParams = { paginate_by: 100, page: 1, name }

      let service = 'getShippersByOrganization'
      let pathParams = { organization_id: this.form.organization_id?.id || this.organization_id }
      if (['admin', 'super_admin'].includes(this.$session.get('cas_user').role)) {
        service = 'getShippers'
        pathParams = {}
        if (this.form.organization_id || this.organization_id) {
          queryParams = { ...queryParams, organization_id: this.form.organization_id?.id || this.organization_id }
        }
      }
      return this.reportsService.callService(service, queryParams, pathParams)
        .then(response => {
          return response.data.map(el => ({ ...el, text: el.name1 }))
        }).catch(err => {
          console.error(err)
          this.$alert('No existen empresas asociadas a la organización')
          return []
        })
    },
    getCarriersByShipper(shipper_id, carrier_selected = null) {
      this.reportsService.callService('getCarriersByShipper', {}, { shipper_id })
        .then(response => {
          const carriers = response.data.associated_carriers || {}
          // map properties from carriers
          const response_carriers = []
          for (const key in carriers) {
            response_carriers.push({ ...carriers[key], text: carriers[key].carrier_name, id: carriers[key].carrier_code })
          }
          this.carriers = response_carriers
          this.fields.find(el => el.name === 'carriers').options = response_carriers
          this.form.carriers = this.carriers.filter(el => el.id === carrier_selected)[0]
        })
        .catch(error => console.error(error))
    },
    getCarriersByOrganization(organization_id, carrier_selected = null) {
      this.reportsService.callService('getCarriersByOrganization', {}, { organization_id })
        .then(response => {
          const carriers = response.data.associated_carriers || {}
          // map properties from carriers
          const response_carriers = []
          for (const key in carriers) {
            response_carriers.push({ ...carriers[key], text: carriers[key].carrier_name, id: carriers[key].carrier_code })
          }
          this.carriers = response_carriers
          this.fields.find(el => el.name === 'carriers').options = response_carriers
          this.form.carriers = this.carriers.filter(el => el.id === carrier_selected)[0]
        })
        .catch(error => console.error(error))
    },
    getCarriers() {
      this.reportsService.callService('getCarriers', {}, {})
        .then(response => {
          this.carriers = response.data.map(el => ({ ...el, text: el.name, id: el.code }))
          this.fields.find(el => el.name === 'carriers').options = this.carriers
        }).catch(err => {
          console.error(err)
          this.$alert('No existen carriers asociados a la empresa')
        })
    },
    changeOrganization(field, value) {
      const organization_id = value?.id || null
      this.getShippers(organization_id, null, null, false)
      if (['admin', 'superadmin'].includes(this.$session.get('cas_user').role)) {
        if (organization_id) {
          this.fields.find(el => el.name === 'shipper_id').validation = ''
        } else if (!this.form.shipper_id) {
          this.fields.find(el => el.name === 'shipper_id').validation = 'required'
        }
      }
      this.getCarriersByOrganization(organization_id)
      if (this.data_fields) {
        this.getFields(true, this.data_fields, this.getCountryId())
      }
    },
    changeShipper(field, value) {
      const shipper_id = value?.id || null
      if (['admin', 'superadmin'].includes(this.$session.get('cas_user').role)) {
        if (shipper_id) {
          this.fields.find(el => el.name === 'organization_id').validation = ''
        } else if (!this.form.organization_id) {
          this.fields.find(el => el.name === 'organization_id').validation = 'required'
        }
      }
      if (shipper_id) {
        this.getCarriersByShipper(shipper_id)
      }
      if (this.data_fields) {
        this.getFields(true, this.data_fields, this.getCountryId())
      }
    },
    getTypes(type = null, data = null) {
      const type_email_report_id = data?.id
      this.reportsService.callService('getTypesReport', {}, {})
        .then(response => {
          this.types = response.data.map(type => {
            return { id: type.id, name: type.name, text: type.name }
          })
          if (this.report_id && type_email_report_id) {
            this.form.types = this.types.find(type => type.id === type_email_report_id)
            this.fields.find(el => el.name === 'range_data').label = `${this.form.types?.text ?? ''} creados el último`
          }
          this.fields.find(el => el.name === 'types').options = this.types
          this.formRenderReports++
        }).catch(err => {
          console.error(err)
          this.$alert(this.$t('msg-problema-cargar-datos', { code: err }))
        })
    },
    getStages(type = null, data = null) {
      this.loading.stages = true
      this.stages = []
      let queryParams = {}
      let stages_selected = []
      if (data) {
        const { id: type_email_report_id, stages = [] } = data
        queryParams = { type_email_report_id }
        stages_selected = stages
      }
      this.reportsService.callService('getStages', queryParams, {})
        .then(response => {
          this.stages = response.data.map(stage => {
            const stage_row = { id: stage.id, name: stage.name, text: stage.name, saved: false }
            if (stages_selected.includes(stage.id)) {
              stage_row.saved = true
              this.form.stages.push(stage.id)
            }
            return stage_row
          })
          this.loading.stages = false
          this.formRenderReports++
        }).catch(err => {
          console.error(err)
          this.$alert(this.$t('msg-problema-cargar-datos', { code: err }))
        })
    },
    getFields(type = null, data = null, country_id = null) {
      this.loading.fields = true
      this.fields_report = []
      let queryParams = {}
      let fields_selected = []
      if (data) {
        const { id, fields = [] } = data
        queryParams = { 'type_email_report_id': id }
        if (country_id) {
          queryParams.country_id = country_id
        }
        fields_selected = fields
      }
      this.reportsService.callService('getFields', queryParams, {})
        .then(response => {
          this.fields_report = response.data.map(field => {
            const field_row = { id: field.id, name: field.name, text: field.name, saved: false }
            if (fields_selected.includes(field.id)) {
              field_row.saved = true
              this.form.fields.push(field.id)
            }
            return field_row
          })
          this.loading.fields = false
          this.formRenderReports++
        }).catch(err => {
          console.error(err)
          this.$alert(this.$t('msg-problema-cargar-datos', { code: err }))
        })
    },
    getCountryId() {
      if (this.form.shipper_id) {
        return this.form.shipper_id.country.id
      } else if (this.form.organization_id) {
        return this.form.organization_id.country.id
      } else {
        return null
      }
    },
    getFielsAndStages(type = null, data = null) {
      // this.fields[2].label = `${data?.text ?? ''} creados el último`
      this.fields.find(el => el.name === 'range_data').label = `${data?.text ?? ''} creados el último`
      if (type && data) {
        this.data_fields = data
        this.getStages(type, data)
        this.getFields(type, data, this.getCountryId())
      } else {
        this.getStages()
        this.getFields()
      }
    },
    selectStage(stage, type) {
      if (type) {
        stage.saved = !stage.saved
        if (stage.saved) {
          this.error_stages = false
          this.form.stages.push(stage.id)
        } else {
          this.form.stages = this.form.stages.filter(item => item !== stage.id)
        }
      }
    },
    selectField(field, type) {
      if (type) {
        field.saved = !field.saved
        if (field.saved) {
          this.error_fields = false
          this.form.fields.push(field.id)
        } else {
          this.form.fields = this.form.fields.filter(item => item !== field.id)
        }
      }
    },
    changeRecipients(data) {
      if (this.error_recipients && data.length > 0) {
        this.error_recipients = false
      }
    },
    changeCheckbox(field, data) {
      const fieldDate = this.fields.find(item => item.name === 'start_date')
      if (fieldDate && data) {
        let field_classes = fieldDate.containerClass.split(' ')
        field_classes = field_classes.filter(item => item !== 'd-none')
        fieldDate.containerClass = field_classes.join(' ')
      } else if (fieldDate) {
        const field_classes = fieldDate.containerClass.split(' ')
        field_classes.push('d-none')
        fieldDate.containerClass = field_classes.join(' ')
      }
    },
    changeRecurrence(field, data) {
      const fieldTimeEnd = this.fields.find(item => item.name === 'time_end')
      const fieldTimeStart = this.fields.find(item => item.name === 'time_start')
      this.form.days = []
      if (data?.id === 'monthly') {
        this.fields.find(item => item.name === 'days').options = this.OptionsDaysOfMonth
      } else {
        this.fields.find(item => item.name === 'days').options = this.OptionsDays
      }
      if (fieldTimeEnd && ['weekly', 'monthly', null].includes(data?.id)) {
        const field_classes = fieldTimeEnd.containerClass.split(' ')
        let field_classes_start = fieldTimeStart.containerClass.split(' ')
        field_classes_start = field_classes_start.filter(item => item !== 'col-md-2')
        field_classes_start.push('col-md-3')
        field_classes.push('d-none')
        fieldTimeEnd.containerClass = field_classes.join(' ')
        fieldTimeStart.containerClass = field_classes_start.join(' ')
        fieldTimeEnd.validation = ''
        this.form.time_end = null
      } else if (fieldTimeEnd) {
        let field_classes = fieldTimeEnd.containerClass.split(' ')
        let field_classes_start = fieldTimeStart.containerClass.split(' ')
        field_classes_start = field_classes_start.filter(item => item !== 'col-md-3')
        field_classes_start.push('col-md-2')
        field_classes = field_classes.filter(item => item !== 'd-none')
        fieldTimeEnd.containerClass = field_classes.join(' ')
        fieldTimeStart.containerClass = field_classes_start.join(' ')
        fieldTimeEnd.validation = 'required'
      }
    },
    changeDays(field, data) {
      const all_days = this.form.days.find(item => item.id === -1)
      if (all_days) {
        if (this.form.days.length === 8) {
          this.form.days = []
          this.formRenderReports++
        } else {
          this.form.days = this.OptionsDays.filter(item => item.id !== -1)
          this.formRenderReports++
        }
      }
    },
    getReportDetails() {
      this.loading.report = true
      this.reportsService.callService('getReport', {}, { id: this.report_id })
        .then(response => {
          this.setDataReportForm(response.data)
        }).catch(err => {
          console.error(err)
          this.$alert(this.$t('msg-problema-cargar-datos', { code: err }))
        })
    },
    setDataReportForm(data) {
      const { name, type_email_report_id, range_data, stages, fields, recurrence, amount_recurrence, days, time_start, time_end, recipients, start_date, shipper_id, shipper_name, organization_id, organization_name, carriers } = data
      this.getStages(true, { id: type_email_report_id, stages })
      this.getTypes(true, { id: type_email_report_id })
      this.data_fields = { id: type_email_report_id, fields }
      this.form.name = name
      this.form.range_data = this.ranges_data.find(item => item.id === range_data)
      this.form.recurrence_form.recurrence = this.recurrenceOptions.find(item => item.id === recurrence)
      this.changeRecurrence(true, this.form.recurrence_form.recurrence)
      this.form.recurrence_form.amount_recurrence = amount_recurrence
      this.form.days = this.form.recurrence_form.recurrence.id === 'monthly' ? this.OptionsDaysOfMonth.filter(item => days.includes(item.id.toString())) : this.OptionsDays.filter(item => days.includes(item.id.toString()))
      this.form.time_start = time_start
      this.form.time_end = time_end
      this.form.recipients = recipients
      this.form.checkbox_date = !!start_date
      this.changeCheckbox(true, !!start_date)
      this.form.start_date = moment(start_date)
      this.loading.report = false
      this.loading.first = false
      this.shipper_id = shipper_id || null
      this.organization_id = organization_id || null
      if (['admin', 'super_admin'].includes(this.$session.get('cas_user')?.role)) {
        const organization_exists = organization_id || organization_name
        const get_fields_by_organization = organization_exists
        const get_fields_by_shipper = !organization_exists
        this.getOrganizations(organization_id, organization_name, get_fields_by_organization)
        this.getShippers(organization_id, shipper_id, shipper_name, get_fields_by_shipper)
      } else if (this.$session.get('cas_user')?.role === 'marketplace') {
        this.getShippers(organization_id, shipper_id, shipper_name)
      } else if (this.data_fields) {
        this.getFields(true, this.data_fields, this.$session.get('cas_user').country.id)
      }
      const carrier_selected = carriers && carriers.length ? carriers[0] : null
      if (shipper_id) {
        this.getCarriersByShipper(shipper_id, carrier_selected)
      } else if (organization_id) {
        this.getCarriersByOrganization(organization_id, carrier_selected)
      } else {
        this.form.carriers = this.carriers.filter(el => el.id === carrier_selected)[0]
      }
      this.formRenderReports++

    }
  }
}
</script>
<style lang="scss" scoped>
span.badge {
  cursor: pointer;
}

span.badge.badge-outlined-primary {
  border: 1px solid #064389;
  color: #064389;
}

span.badge.badge-outlined-default {
  border: 1px solid #d3d4d6;
  color: #d3d4d6;
}

.badge {
  padding: 0.6rem 0.6rem;
  border-radius: 0.9rem;
}

.badge i {
  font-size: 20px;
  position: relative;
  margin-right: 10px;
  margin-top: -4px;
  margin-left: -2px;
}

.m-0-5 {
  margin: 0.5rem;
}

.color-white {
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
}

.out-border-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}

.out-border-right:focus {
  border-right: 1px solid #044389;
}
</style>